import { useUserStore } from '@/stores/userStore';

export default defineNuxtRouteMiddleware((to) => {
  // Only run on team pages
  if (!to.path.startsWith('/team/')) return;

  const teamId = to.params.id as string;
  if (!teamId) {
    return navigateTo('/');
  }

  const userStore = useUserStore();
  const team = userStore.teams.find(t => t.id === teamId);
  
  // If team is found in store, set it as selected
  if (team) {
    userStore.setSelectedTeam(team);
  }
}); 